import {useState, useEffect} from 'react';
import { answer } from '../../services/answers';
import { useSnackbar } from 'notistack';
import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Grid,
  StepConnector,
  Box
} from '@mui/material';
import { Formik, Form } from 'formik';
import SurveySuccess from '../../routes/SurveySuccess';
import { addRequiredErrorMsg } from '../formFunctions/addRequiredErrorMsg';
import { addInitialValues } from '../formFunctions/addInitialValues';
import { makeAnswerList } from '../formFunctions/makeAnswerList';
import { renderQuestions } from '../formFunctions/renderQuestions';

const Survey = ({backForm, surveyQueueId,preview}) => { 

  //ESTRUCTURACION
  const { formId, steps } = backForm;
  const [activeStep, setActiveStep] = useState(0);
  const currentStep = steps[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { enqueueSnackbar } = useSnackbar();
  
  //Agregado de 'requiredErrorMsg' segun 'questionType'
  addRequiredErrorMsg(backForm);
 
  //Agregado de 'name' para el manejo de inputs
  backForm.steps.forEach((step) => {
    step.questionList.forEach((question) => {
      question.name = question.id.toString();
    });
  });
 
  //Armado de objeto initialValues
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    setInitialValues(addInitialValues(initialValues, steps));
  }, []);

  //ENVIO DE RESPUESTAS
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  async function _submitForm(values, actions) {
    //Armado del objeto respuesta
    if(preview) {
      actions.setSubmitting(false);
      enqueueSnackbar("No es posible enviar la encuesta en modo de pre-visualización", { 
        preventDuplicate: true, 
        variant: 'info'
      });
      return;
    }
    await _sleep(1000);

    const surveyAnswer= {
      surveyQueueId: Number(surveyQueueId),
      answerList: makeAnswerList(values, backForm),
      completed: Boolean(false)
    };
    
    if (surveyAnswer.answerList.length < 1) {
      enqueueSnackbar("La encuesta no se envió pues ninguna de las preguntas fue contestada.", { 
        preventDuplicate: true, 
        variant: 'error'
      });
      actions.setSubmitting(false);
    } else {
      surveyAnswer.completed = true;

      try {
        await answer.answerSurvey(surveyAnswer);
        enqueueSnackbar(("Se envió el formulario correctamente"), {
          preventDuplicate: true,
          variant: 'success'
        });
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
      } catch (error) {
        enqueueSnackbar("Ocurrió un error al enviar la encuesta", {
          preventDuplicate: true,
          variant: 'error'
        });
        actions.setSubmitting(false);
      }
    }
  };

  function _handleSubmit(values, actions) {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  function _handleBack() {
    setActiveStep(activeStep - 1);
  };


  /**
   * @param {Object} answers
   * Keys: questionID
   * <br>
   * Values: dependen del questionType
   *   - SHORT, LONG, LINEAR_SCALE: {String/Number} valor de la respuesta
   *   - OPTIONS: {String} optionID
   *   - MATRIX: {Object}
   *       Keys: subquestion.title
   *       Values: {String} optionID
   * @returns {Object} errors :
   *  <br>
   *  Keys: questionID
   *  <br>
   *  Values: {String} Error msg
   */
  const questionValidation = (answers) => {
    let errors = {};
    for (const questionId in answers) {
      let question = currentStep.questionList.find((q) => q.id.toString() === questionId.toString());
      if (question.obligatory) {
        switch (question.questionType) {
          case "CHECKBOX":
            if (!answers[questionId].length) {
              errors[questionId] = question.requiredErrorMsg || "Campo requerido";
            }
            break;

          case "MATRIX":
            validateMatrix(errors, answers[questionId], question);
            break;

          default:
            if (!answers[questionId]) {
              errors[questionId] = question.requiredErrorMsg || "Campo requerido";
            }
            break;
        }
      } else if (question.questionType === 'MATRIX'){
        //Aunque Matrix no sea obligatoria, si se contesta, se tienen que contestar todas sus subpreguntas.
        validateMatrix(errors, answers[questionId], question);
      }
    }
    return errors;
  }

  const validateMatrix = (errors, matrixAnswer, question) => {
    for (let matrixq in matrixAnswer) {
      //Aca la subquestion/row se representa con el title como property
      if (matrixAnswer.hasOwnProperty(matrixq)) {
        let subq = matrixAnswer[matrixq];
        if (subq === null || subq === undefined) {
          errors[question.id] = question.requiredErrorMsg || "Campo requerido";
          break;
        }
      }
    }
  };

  //RENDER
  const conditionalContent = (
    <>
      <Stepper  
        alternativeLabel={true} 
        activeStep={activeStep} 
        sx={{ margin: "3rem 5rem 1rem 5rem"}} 
        connector={<StepConnector sx={{ margin: "0.7rem 2rem 1rem 2rem"}} />}>
          {(steps.length > 1) && steps.map((step) => (
            <Step key={step.step}>
              <StepLabel  
                className='cust-step'
                StepIconProps={{ 
                  style: { fontSize: "3rem", margin: "0 1rem 0 1rem"} 
                }}>
                  {step.stepName}
                </StepLabel>
            </Step>
          ))}
      </Stepper>
      <>
      {activeStep === steps.length ? (
          <SurveySuccess redirectUrl={backForm.redirectUrl} />
        ) : (
          <Formik
          initialValues={initialValues}
          validate={questionValidation}
          onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {currentStep.questionList
                    .sort((a, b) => a.questionIndex - b.questionIndex)
                    .map((question) => (
                        <Stack key={question.name} spacing={2} sx={{ display: "flex", alignItems: "center"}} >
                          {renderQuestions(question)}
                        </Stack>
                    ))}
                <Grid container spacing={4} sx={{ padding: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} >
                  {activeStep !== 0 && (
                    <Grid item>
                      <Button 
                      className='cust-button send-answers-button cust-button-back-submit'
                      disabled={isSubmitting}
                      variant="contained"
                      sx={{minWidth: "7rem"}}
                      onClick={_handleBack} >
                        Atrás
                      </Button>
                    </Grid>
                  )}
                <Grid item>
                  <Button
                    className='cust-button send-answers-button cust-button-next-submit'
                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    sx={{minWidth: "7rem"}}
                  >
                    {isSubmitting ? 'Enviando...' : isLastStep ? 'Enviar' : 'Siguiente'}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  </>
);

return (
    backForm ? conditionalContent : null
  );
};

export default Survey;
